.navbar-custom {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); /* Adjust shadow properties */
  transition: box-shadow 0.3s ease; /* Optional: Add transition for smooth effect */
}

.navbar-custom .navbar-brand {
  color: #333333; /* Dark Gray for brand name */
}

.navbar-custom .navbar-nav .nav-link {
  color: #0E73DB; /* Deep Blue for links */
  font-weight: bold;
}

.navbar-custom .navbar-nav .nav-link:hover {
  color: #EDB50D; /* Soft Yellow for hover effect */
}
