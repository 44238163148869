/* Light Mode */
.light-mode {
  --bg-color: #FFFFFF;
  --text-color: rgba(46, 46, 46, 1);
  --primary-color: #0E73DB;
  --secondary-color: #EDB50D;
  --link-color: #0E73DB;
}

/* Dark Mode */
.dark-mode {
  --bg-color: #202020;
  --text-color: #FFFFFF;
  --primary-color: #EDB50D;
  --secondary-color: #0E73DB;
  --link-color: #EDB50D;
}

/* Apply theme colors */
body, .navbar-custom, .container, .footer {
  background-color: var(--bg-color);
  color: var(--text-color);
}

.navbar-custom .navbar-nav .nav-link {
  color: var(--text-color);
}

a {
  color: var(--link-color);
}

button {
  background-color: var(--primary-color);
  color: var(--text-color);
}

h1, h2, h3, h4, h5, h6, p, span {
  color: var(--text-color);
}
