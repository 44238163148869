/* Styles for Light Theme */
.app.light {
  background-color: #ffffff; /* Light background color */
  color: #000000; /* Dark text color */
}

/* Styles for Dark Theme */
.app.dark {
  background-color: #121212; /* Dark background color */
  color: #ffffff; /* Light text color */
}

/* Additional styles for your MainPage */
.main-container {
  padding: 3rem 1rem; /* Increased padding */
}

.hero-title {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.hero-name {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.hero-description {
  font-size: 1.2rem;
  max-width: 600px; /* Max width for better readability */
  margin: 0 auto; /* Center text */
}

/* Social Icons */
.social-icons {
  display: flex;
  justify-content: center;
}

/* Adjustments for navbar */
.navbar-custom {
  background-color: #F5F5F5; /* Light Gray for navbar background */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow for separation */
}

.navbar-custom .navbar-brand,
.navbar-custom .navbar-nav .nav-link {
  color: #0E73DB; /* Deep Blue for links */
}

.navbar-custom .navbar-nav .nav-link:hover {
  color: #EDB50D; /* Soft Yellow for hover effect */
}

/* Dark Mode Adjustments */
.navbar-custom.dark {
  background-color: #343a40; /* Dark navbar background */
}

.navbar-custom.dark .navbar-brand,
.navbar-custom.dark .navbar-nav .nav-link {
  color: #ffffff; /* Light links */
}

.floating-img {
  animation: float 3s infinite; /* Apply the float animation infinitely */
}

@keyframes float {
  0% {
    transform: translateY(0); /* Initial position */
  }
  50% {
    transform: translateY(-10px); /* Move up */
  }
  100% {
    transform: translateY(0); /* Return to initial position */
  }
}